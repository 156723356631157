import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import SpacingVertical from "../components/SpacingVertical";
import PostList from "../components/PostList";
import CallToAction from "../components/CallToAction";
import Strings from "../constants/Strings";
import Colors from "../constants/Colors";
import "../components/Layout.css";

const Blog = (props) => {
  const { data, location } = props;
  return (
    <Layout override="marginStandard">
      <SEO title={Strings.Blog.title} url={location.pathname} />
      <div className="marginFocus">
        <SpacingVertical rems={4} />
        <h1 style={styles.noMargin}>{Strings.Blog.title}</h1>
        <PostList
          posts={data.allMarkdownRemark.edges}
          title={Strings.Blog.title}
        />
      </div>
      <SpacingVertical rems={4} />
      <CallToAction
        backgroundColor={Colors.grayLight}
        inputColor={Colors.white}
        labelColor={Colors.blueDark}
      />
      <SpacingVertical rems={4} />
    </Layout>
  );
};

const styles = {
  noMargin: { marginBottom: "0rem" },
};

export default Blog;

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "blog" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
          }
          fields {
            slug
          }
          excerpt(pruneLength: 280)
        }
      }
    }
  }
`;
